@import "assets/theme";
@import "./assets/_mixin.scss";
@import './assets/_variable.scss';

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "proxima_nova";
  src: url("./assets/font/proxima_nova_regular.ttf");
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
  font-family: "proxima_nova", "Helvetica Neue", sans-serif;

  * {
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
  }

}

.mat-tab-list {
  .mat-tab-label {
    .mat-tab-label-content {
      white-space: break-spaces;
    }
  }
}


.tab-form {
  @include tabForm();

  .section-container {
    @include sectionContainer();

    .section-box {
      @include sectionBox();

      .section-label {
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
      }
    }
    .content-width-100{
      padding: 0;
    }
  }

  .btn-section {
    @include buttonContainer();
  }
}


::-webkit-scrollbar {
  display: none;
}

.required-indicator{
  color: $errorColor;
}

// for bottom sheet 
.bottom-sheet-container {
  height: 100%;
  background-color: #fff;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 18px 15px;
    height: 60px;
    box-shadow: 0px 1px 4px #0000001A;
    font-size: 20px;
    gap: 15px;
  }

  .bottom-sheet-content {
    margin-top: 1em;
    margin-inline: 25px;
    .btn-container {
      display: flex;
      justify-content: center;
      padding-block: 1em;
    }
  }

}