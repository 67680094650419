@import './_variable.scss';
@mixin formAndSection($formHeight: calc(100vh - 100px), $sectionContainerHeight: calc(100vh - 180px)) {
  padding: 15px;
  height: $formHeight;

  .sections-container {
    @include sectionContainer()
  }
}

@mixin sectionBox($cardBorderColor: #3D7CC9) {
  border: 1px solid $cardBorderColor;
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px;
}

@mixin btnContainer() {
  display: flex;
  justify-content: center;
  align-items: center;

  .back-btn {
    border-color: #965EC7;
    color: #965EC7;
    width: 100%;
  }

  .back-btn+.save-btn {
    width: 100%;
  }
}

@mixin inputBoxs($borderColor: #666A70) {
  border: none;
  outline: none;
  border-radius: 0;
  border-bottom: 1px solid $borderColor;
  padding-bottom: .5em;
  margin-top: .5em;
  width: 100%;
  padding-right: 3.5em;
}

@mixin bottomSheetHeader() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 15px;
  height: 60px;
  box-shadow: 0px 1px 4px #0000001A;
  font-size: 20px;
  gap: 15px;
}


@mixin checkBoxContainer() {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 1em;
  gap: .8em;
  max-height: 49vh;

  .check-box-items {
    @include matCheckBoxClassCss();
  }
}

@mixin matCheckBoxClassCss() {
  .mat-checkbox-layout {
    gap: 1em;
  }
}


// mixin for all other than gynae

@mixin tabForm() {
  padding: 15px;
  height: calc(100vh - 100px);
}

@mixin sectionContainer($sectionContainerHeight: calc(100vh - 195px)) {
  height: $sectionContainerHeight;
  max-height: $sectionContainerHeight;
  overflow: auto;
  margin-bottom: .8em;
}

@mixin sectionBox($cardBorderColor: #3D7CC9) {
  border: 1px solid $cardBorderColor;
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px;
}

@mixin buttonContainer() {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5em;
  .btn{
    width: 100%;
  }
}

@mixin ageContainer(){
  .age-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-size: 14px;
      font-weight: 200;
      width: $labelWidth;
      margin-top: 10px;
    }
    .input-error-container{
      width: 70%;
      .input-container {
        display: flex;
        gap: 4%;

        .input-box {
          width: 50%;
          border: none;
          border-bottom: .1em solid $borderColor;
          border-radius: 0;
          padding-left: 5px;
          padding-bottom: 8px;
          margin-top: 16px;
        }

        .input-box::placeholder {
          color: $borderColor;
        }

        .input-box:focus-visible {
          outline: none;
        }
      }
      .error-message{
        color: $errorColor;
        padding-top: 5px;
        font-size: 10px;
        font-style: italic
      }
    }
  }
}